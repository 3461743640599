import Vue from "vue"
import Router from "vue-router"
import store from "./store"

Vue.use(Router)

const router = new Router({
  // Code splitting in routes breaks this.$route in created() hook?
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/results",
      name: "results",
      component: () => import("./views/Results.vue"),
    },
  ],
})

// // EXECUTED BEFORE ROUTING

// router.beforeEach(async (to, from) => {
//   // Routes that skip authorization

//   const noAuthRoutes = ["NotAuthorized"]

//   // Routes that will require superUser status for user

//   const superUserRoutes = ["AdminExample"]

//   // Skip Authentication for noAuthRoutes

//   if (noAuthRoutes.includes(to.name)) {
//     return
//   }

//   // If there is no token saved, get ticket from webauth, and use that to get a JSON Web Token

//   // Token will be used for authentication with all subsequent API calls.

//   if (!store.token || store.token == "undefined") {
//     const webAuthURL = "https://webauth.arizona.edu/webauth/login?service="

//     let location = to.path

//     // See vite.config.js for base (used in deployment)

//     let serviceURL = window.location.origin // +
//     //let serviceURL = "http://localhost:8081"

//     //(import.meta.env.BASE_URL != "/" ? import.meta.env.BASE_URL : "")

//     const params = new URLSearchParams(window.location.search)

//     const ticket = params.get("ticket")

//     // If we dont have a token, and don't have the ticket yet, go to Webauth to get a ticket.

//     if (!ticket) {
//       window.location.replace(webAuthURL + serviceURL + location)

//       return false
//     } else {
//       try {
//         let token = await store.dispatch("getToken", {
//           ticket,
//           location,
//           serviceURL,
//         })

//         if (!token) return false // Prevent navigation if token retrieval fails

//         cleanUpURL()

//         return { path: location, query: {} }
//       } catch (error) {
//         console.error("Error fetching token:", error)

//         return false // Stop navigation
//       }
//     }
//   }

//   // Fetch any data that is needed before going on (superUser, etc)

//   //await user.initialize()

//   // Some routes require superUser status

//   /* if (superUserRoutes.includes(to.name) && !user.isSuperUser) {

//     console.log("Denied")

//     return "/NotAuthorized"

//   } */

//   // Proceed to the route

//   return
// })

// function cleanUpURL() {
//   const url = new URL(window.location.href)

//   url.search = "" // Remove query parameters

//   window.history.replaceState({}, document.title, url.toString())
// }

export default router
