<template>
  <div id="app" class="d-flex flex-column h-100">
    <Navigation />
    <div id="app-content">
      <SlideXLeftTransition mode="out-in">
        <!-- Prevents unpublished term preview features when unauthenticated -->
        <div v-if="$adminPreview" class="h-page">
          <router-view />
        </div>
        <div v-else class="h-page">
          <router-view />
        </div>
      </SlideXLeftTransition>
    </div>
    <Toasts />
    <Footer class="mt-auto"></Footer>
  </div>
</template>

<script>
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"
import { SlideXLeftTransition } from "vue2-transitions"
import Toasts from "./components/Toasts.vue"
import Footer from "./components/Footer.vue"
import Navigation from "./components/Navigation.vue"
//import AuthGuard from "@uarizona-fnsv/vue-doggo/AuthGuard"

export default {
  components: {
    SlideXLeftTransition,
    //AuthGuard,
    Toasts,
    Navigation,
    Footer,
  },
  // async beforeMount() {
  //   this.$gtag.pageview(this.$route)
  //   if (this.$adminPreview) {
  //     await this.$auth.login()
  //   }
  // },
}
</script>

<style lang="postcss">
html,
body,
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#app-content {
  flex-grow: 1;
  margin: 2rem auto 3rem auto;
  max-width: 700px;
  width: 100%;
}
</style>
