import Vue from "vue"
import Vuex from "vuex"

import api from "./utils/api"
import Router from "./router"
import router from "./router"
import jscookie from "js-cookie"

import { convertString } from "./utils"

export const API_JWT_AUTH = "tuitioncalc_jwt_auth"

Vue.use(Vuex)

// Note that arrays are used because the order of their contents is important!
const state = {
  selections: [],
  dropdowns: [],
  results: false,
  typeDescriptor: null,
  toasts: [],
  previewMode: false,
  token: jscookie.get(API_JWT_AUTH),
  useLocalTokenAPI: false,
}

export const mutations = {
  // Maintain reactivity by using Vue.set & .delete when adding/replacing/deleting state keys!
  storeSelection(state, payload) {
    state.selections.push(payload)
  },
  storeQuerySelections(state, payload) {
    Vue.set(state, "selections", payload.selections)
    Vue.set(state, "dropdowns", payload.dropdowns)
  },
  storeDropdowns(state, payload) {
    state.dropdowns.push(...payload)
  },
  storeResults(state, payload) {
    Vue.set(state, "results", payload.results)
    Vue.set(state, "typeDescriptor", payload.type_descriptor)
  },
  addToast(state, payload) {
    state.toasts.push(payload)
  },
  removeToast(state, payload) {
    Vue.delete(state.toasts, payload)
  },
  /**
   * Delete any rates, and all selections & dropdowns beginning with those
   * which correspond to `startId`, through the end of the array.
   */
  resetById(state, startId) {
    Vue.set(state, "results", null)

    const startIndex = state.selections.findIndex(({ id }) => id === startId)
    if (startIndex === -1) return

    state.selections.splice(startIndex)
    if (state.dropdowns.length > startIndex + 1) {
      state.dropdowns.splice(startIndex + 1)
    }
  },
  /** Reset rates, selections, & dropdowns to initial state. */
  resetAll(state) {
    Vue.set(state, "results", null)
    Vue.set(state, "selections", [])
    if (state.dropdowns.length > 1) {
      state.dropdowns.splice(1)
    }
  },
  resetDropdowns(state) {
    Vue.set(state, "results", null)
    Vue.set(state, "dropdowns", [])
    Vue.set(state, "selections", [])
  },
  storePreviewMode(state, previewMode) {
    previewMode = previewMode && window.location.pathname === "/admin/preview/"
    Vue.set(state, "previewMode", previewMode)
  },
  setToken(state, payload) {
    console.log("Setting Token", payload)

    if (payload) {
      jscookie.set(API_JWT_AUTH, payload, { expires: 1 })
    } else {
      jscookie.remove(API_JWT_AUTH)
    }

    state.token = payload
  },
}

export const actions = {
  /** Set the preview state for the admin */
  async togglePreviewMode({ commit, dispatch, state, getters }) {
    if (window.location.pathname !== "/admin/preview/") return
    commit("resetDropdowns")
    commit("storePreviewMode", !state.previewMode)
    const [error, res] = await api.get(
      `/dropdowns/start/?preview=${state.previewMode}`,
    )
    if (error) {
      dispatch("handleError", error)
    } else {
      commit("storeDropdowns", res.data.dropdowns)
    }
    dispatch("updateQuerySelections")
  },
  loadQueryPreview({ commit }) {
    const { ...querySelections } = { ...Router.currentRoute.query }
    if (querySelections.preview === "true") commit("storePreviewMode", true)
  },
  /** Fetch the first dropdown, Select Term, from the API. */
  async fetchTerms({ commit, dispatch, state }) {
    dispatch("loadQueryPreview")
    const [error, res] = await api.get(
      `/dropdowns/start/?preview=${state.previewMode}`,
    )
    if (error) {
      dispatch("handleError", error)
    } else {
      commit("storeDropdowns", res.data.dropdowns)
    }
  },
  /** Load selections from the query string and query API for dropdowns. */
  async loadQuerySelections({ commit, dispatch, state }) {
    dispatch("loadQueryPreview")
    const { ...querySelections } = { ...Router.currentRoute.query }
    const selections = []
    Object.entries(querySelections).forEach(([index, selection]) => {
      selections[index] = {
        id: selection[0],
        value: convertString(selection[1]),
      }
    })

    const [error, res] = await api.post(
      `/dropdowns/resume/?preview=${state.previewMode}`,
      selections,
    )
    if (error) {
      // Start over if resume fails
      commit("resetDropdowns")
      await dispatch("fetchTerms")
    } else {
      const dropdownOptions = res.data.dropdowns.map(option => option.id)
      const relevantSelections = selections.filter(option =>
        dropdownOptions.includes(option.id),
      )
      commit("storeQuerySelections", {
        selections: relevantSelections,
        dropdowns: res.data.dropdowns,
      })
      dispatch(
        "makeSelection",
        relevantSelections[relevantSelections.length - 1],
      )
    }
  },
  /** Store selection, post all selections to API, store the returned data. */
  async makeSelection({ commit, dispatch, state }, payload) {
    commit("resetById", payload.id)
    commit("storeSelection", payload)
    dispatch("updateQuerySelections")
    const [error, res] = await api.post(
      `/dropdowns/${payload.id}/`,
      state.selections,
    )
    if (error) {
      dispatch("handleError", error)
    } else if (!res.data.dropdowns && !res.data.tuition_types) {
      dispatch(
        "handleError",
        new Error("Could not find tuition types matching selections"),
      )
    } else {
      res.data.dropdowns && commit("storeDropdowns", res.data.dropdowns)
      res.data.results && commit("storeResults", res.data)
    }
  },
  /** Update the query string with selections. */
  async updateQuerySelections({ state }) {
    const selections = []
    state.selections.forEach(selection =>
      selections.push([...Object.values(selection)]),
    )
    const query = { ...selections }
    query.preview = state.previewMode

    Router.push({ query }).catch(() => {
      // Sometimes this will throw NavigationDuplicated, which is stupid, and can be ignored.
    })
  },
  /** Reset all selections, rates, dropdowns, and the query string. */
  reset({ commit, dispatch }) {
    commit("resetAll")
    dispatch("updateQuerySelections")
  },
  handleError({ commit }, error) {
    commit("addToast", {
      header: "Error",
      body: `Please try again or contact support.`,
    })
    console.error(error)
  },
  async getToken({ commit, getters }, payload) {
    console.log("Get Token")
    try {
      const response = await fetch(getters.jwtUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        router.push({ name: "ServiceDown" })
        return null
      }

      const data = await response.json()
      commit("setToken", data.token) // Use Vuex mutation
      return data.token // Return token
    } catch (error) {
      console.error("Error fetching token:", error)
      router.push({ name: "ServiceDown" })
      return null
    }
  },
}

const getters = {
  jwtUrl: state =>
    state.useLocalTokenAPI
      ? "https://localhost:7225/getDoggoJwt"
      : "https://api.ba.arizona.edu/common/getDoggoJwt",

  myHeaders: () => {
    const headers = new Headers()

    headers.append("Authorization", `Bearer ${store.token}`)

    headers.append("Accept", "application/json; indent=4")

    headers.append("Content-Type", "application/json")

    return headers
  },
}

export default new Vuex.Store({ state, mutations, actions, getters })
