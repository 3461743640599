import axios from "axios"

axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? "http://localhost:8000" : "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

// This allows us to avoid try/catch blocks in async code.
// ex. `[error, res] = await api.get("/")`
// See https://blog.grossman.io/how-to-write-async-await-without-try-catch-blocks-in-javascript/
api.interceptors.response.use(
  response => [null, response],
  error => [error, undefined],
)

export default api
